import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import cancel from "../images/cancel.png";

const NotifiContainer = styled.div`
  position: relative;
  grid-column: 1 / span 2;
  background: #635bff;
  font-size: 16px;
  color: white;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1fr 25px;
  grid-gap: 15px;
  justify-items: center;
  @media (max-width: 575px) {
    padding: 10px 20px 10px 20px;
    grid-column: 1 / span 1;
  }
`;

const Cross = styled.div`
  text-align: center;
  line-height: 25px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 13px;
`;
const Highlight = styled.span`
  color: #ffc83d;
  font-weight: 600;
`;

const UpperNotification = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let today = moment();
    let startDate = moment("02-12-2024", "DD-MM-YYYY");
    let endDate = moment("01-01-2025", "DD-MM-YYYY");

    setShow(today.isBetween(startDate, endDate));
  }, []);

  const closeShow = () => {
    setShow(false);
  };

  return show ? (
    <NotifiContainer>
      <div>
        {" "}
        <span>{props.spark}</span>
        Grab 25% special discount before it runs out. Apply the coupon:{" "}
        <Highlight>MONDAY25%</Highlight> <span>{props.spark}</span>
      </div>
      <Cross onClick={() => closeShow()}>
        <img src={cancel} alt="" width="100%" />
      </Cross>
    </NotifiContainer>
  ) : (
    <></>
  );
};

export default UpperNotification;
